import { Controller } from "@hotwired/stimulus"
// https://scottw.com/articles/stimulus_toggler_for_tailwindui/
// TODO: 1) check how targets work exactly. Maybe nodeName check isn't needed that way. (https://stimulus.hotwired.dev/reference/targets)
//       2) aria-checked="<%= template.active %>" on the button should be taken in account in here too
export default class extends Controller {
  static targets = [ "element" ]

  toggle(event) {
    event.preventDefault();
    this.elementTargets.forEach((element) => {
      if (element.nodeName == "BUTTON") {
        if (element.classList.contains("bg-gray-200")) {
          element.classList.remove("bg-gray-200", "dark:bg-gray-700");
          element.classList.add("bg-blue-500", "dark:bg-blue-500");
        } else {
          element.classList.add("bg-gray-200", "dark:bg-gray-700");
          element.classList.remove("bg-blue-500", "dark:bg-blue-500");
        }
      }
      if (element.nodeName == "SPAN") {
        if (element.classList.contains("translate-x-0")) {
          element.classList.remove("translate-x-0");
          element.classList.add("translate-x-5");
        } else {
          element.classList.add("translate-x-0");
          element.classList.remove("translate-x-5");
        }
      }
      if (element.nodeName == "INPUT") {
        if (element.checked) {
          element.checked = false;
        } else {
          element.checked = true;
        }
      }
    });
  }
}